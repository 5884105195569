import React from "react";
import "../styles/Components.scss";
import {
  ClockTypes,
  DayNames,
  Languages,
  MonthNames,
  Themes,
  TimeFormats,
  TimeOfDay,
} from "../constants/constants";
import { convertToPascalCase } from "./common/Utilities";
import KonnectLogo from "../static/konnect_logo_white.svg";
import KonnectShortLogo from "../static/konnect_logo_short.svg";

const LangStrings = require("../config/translations.json");

const DefaultTheme = Themes.dark;
const DefaultLanguage = Languages.english;
const DefaultClockType = ClockTypes.digital;
const DefaultTimeFormat = TimeFormats[24];
const DefaultContrast = true;

class KonnectClock extends React.Component {
  constructor(props) {
    super(props);
    console.log("KonnectClock props", props);

    /** get query params */
    const queryParams = new URLSearchParams(window.location.search);
    this._lang = queryParams.get("language") || DefaultLanguage;
    this._theme = queryParams.get("theme") || DefaultTheme;
    this._type = queryParams.get("type") || DefaultClockType;
    this._format = queryParams.get("format") || DefaultTimeFormat;
    this._contrast =
      (queryParams.get("contrast") || DefaultContrast) === "true";
    console.log(
      `KonnectClock params > Theme: ${this._theme}, Language: ${this._lang}, Clock Type: ${this._type}, Clock Format: ${this._format}, Clock Contrast: ${this._contrast}`
    );

    this.state = { date: new Date() };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
    window.addEventListener("resize", this.resetParams);
    this.resetParams();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
    window.removeEventListener("resize", null);
  }

  resetParams = () => {
    const { innerWidth: width, innerHeight: height } = window;
    console.log("window dim", width, height);
    const base = Math.min(height, width);
    const params = {
      fontSize1: Math.ceil(base * 0.08),
      fontSize2: Math.ceil(base * 0.05),
      fontSize3: Math.ceil(base * 0.04),
      logoScale: base * 0.0011,
    };
    console.log("window params", params);
    this.setState({ params });
  };

  tick = () => {
    this.setState({ date: new Date() });
  };

  dayName = () => {
    const trans = LangStrings?.dayNames || {};
    const dayNames = trans[this._lang] || DayNames;
    const day = this.state.date.getDay();
    return convertToPascalCase(dayNames[day] || DayNames[day]);
  };

  monthName = () => {
    const trans = LangStrings?.monthNames || {};
    const monthNames = trans[this._lang] || MonthNames;
    const month = this.state.date.getMonth();
    return convertToPascalCase(monthNames[month] || MonthNames[month]);
  };

  timeOfDay = () => {
    const trans = LangStrings?.timeOfDay || {};
    const timeOfDay = trans[this._lang] || TimeOfDay;
    const hours = this.state.date.getHours();
    /**
     * HOMESIGHT-962
     * 00:00 to 06:00 > Night (12pm-6am)
     * 06:00 to 11:59 > Morning (6am-12pm)
     * 12:00 to 17:59 > Afternoon (12pm-6pm)
     * 18:00 to 23:59 > Evening (6pm-12pm)
     */
    let index = 0;
    if (hours >= 18) index = 3;
    else if (hours >= 12) index = 2;
    else if (hours >= 6) index = 1;
    return convertToPascalCase(timeOfDay[index] || TimeOfDay[index]);
  };

  timeString = () => {
    const minutes = this.state.date.getMinutes();
    const hours = this.state.date.getHours();
    const minuteString = ("0" + minutes).slice(-2);
    const hourString = ("0" + hours).slice(-2);
    let time = "";
    if (this._format === TimeFormats[24]) {
      time = hourString + " : " + minuteString;
    } else {
      var ampm = hours >= 12 ? "PM" : "AM";
      const newHours = hours % 12;
      // const newHourString = ("0" + newHours).slice(-2);
      time = newHours + " : " + minuteString + " " + ampm;
    }
    return time;
  };

  dayString = () => {
    const { date } = this.state;
    const day = date.getDate();
    let ext = "th";
    if (day % 10 === 1 && day !== 11) {
      ext = "st";
    } else if (day % 10 === 2 && day !== 12) {
      ext = "nd";
    } else if (day % 10 === 3 && day !== 11) {
      ext = "rd";
    }
    const trans = LangStrings?.dayExtensions || {};
    const dayExt = trans[this._lang] || {};
    return (
      day +
      (dayExt[ext] || "") +
      " " +
      (dayExt["of"] || "") +
      " " +
      this.monthName() +
      " " +
      date.getFullYear()
    );
  };

  render() {
    const { params = {} } = this.state;

    return (
      <div className={`konnect-clock main-container ${this._theme}-theme`}>
        <div className="main-grid">
          <div className="clock-container">
            <span
              className="header"
              style={{
                fontSize: `${params.fontSize1}px`,
                lineHeight: `${params.fontSize1 * 3}px`,
              }}
            >
              <img
                src={KonnectLogo}
                alt="Konnect"
                style={{
                  fontSize: params.fontSize1,
                  transform: `scale(${params.logoScale})`,
                }}
              />
            </span>

            <span
              className="header"
              style={{
                fontSize: `${params.fontSize1}px`,
                lineHeight: `${params.fontSize1}px`,
              }}
            >
              {this.dayName()} {this.timeOfDay()}
            </span>

            <span
              className="header"
              style={{
                fontSize: `${params.fontSize1}px`,
                lineHeight: `${params.fontSize1 * 1.2}px`,
              }}
            >
              {this.timeString()}
            </span>

            <span
              className="header"
              style={{
                fontSize: `${params.fontSize2}px`,
                lineHeight: `${params.fontSize2 * 3}px`,
                marginTop: `${params.fontSize2}px`,
              }}
            >
              {this.dayString()}
            </span>
          </div>
          <div className="footer-container">
            <img
              className="logo"
              src={KonnectShortLogo}
              alt="K"
              style={{ fontSize: params.fontSize2 }}
            />
            <span
              className="text"
              style={{ fontSize: `${params.fontSize3}px` }}
            >
              Main Menu
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default KonnectClock;
